// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-leistungen-js": () => import("./../../../src/pages/leistungen.js" /* webpackChunkName: "component---src-pages-leistungen-js" */),
  "component---src-pages-projektanfrage-js": () => import("./../../../src/pages/projektanfrage.js" /* webpackChunkName: "component---src-pages-projektanfrage-js" */),
  "component---src-pages-referenzen-js": () => import("./../../../src/pages/referenzen.js" /* webpackChunkName: "component---src-pages-referenzen-js" */),
  "component---src-pages-ueber-uns-js": () => import("./../../../src/pages/ueber-uns.js" /* webpackChunkName: "component---src-pages-ueber-uns-js" */),
  "component---src-pages-webdesign-js": () => import("./../../../src/pages/webdesign.js" /* webpackChunkName: "component---src-pages-webdesign-js" */),
  "component---src-templates-cp-page-simple-js": () => import("./../../../src/templates/cp-page-simple.js" /* webpackChunkName: "component---src-templates-cp-page-simple-js" */),
  "component---src-templates-testimonial-detail-js": () => import("./../../../src/templates/testimonial-detail.js" /* webpackChunkName: "component---src-templates-testimonial-detail-js" */)
}

